import { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Typography, Button, Space, Table, Modal, Form, Input, Select } from 'antd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import {
    getMockExamPage,
    delMockExam,
    hiddenMockExam,
    createMockExam,
    updateMockExam,
    getAllMockExams,
    updateMockExamSort,
} from '../../../services/MockExamRequest'
import { getPaperNames } from '../../../services/PaperRequest'
import PaperChooser from '../../kw/exam/detail/paperSetting/PaperChooser'
import immer from 'immer'
import './index.styl'

const { Title } = Typography

const Index = () => {
    const [formStatus, setFormStatus] = useState({
        visible: false,
        exam: null,
    })

    const [sortModal, setSortModal] = useState(false)

    const [exams, setExams] = useState({
        totalElements: 0,
        content: [],
        number: 0,
        page: 0,
    })

    const fetch = useCallback((page = 1, size = 10, search = {}) => {
        return getMockExamPage(page, size, search).then((res) => {
            setExams(res)
        })
    }, [])

    useEffect(() => {
        fetch(1, 10)
    }, [fetch])

    const onDelItem = (id) => {
        Modal.confirm({
            title: '删除确认',
            content: '确认要删除此模拟演练吗？',
            onOk: () => delMockExam(id).then(() => fetch()),
        })
    }

    const onSettingModalClick = (item) => {
        setFormStatus({ visible: true, exam: item })
    }

    const onSwitch = (id, hidden) => {
        function action() {
            return hiddenMockExam(id, !hidden).then((res) => {
                setExams(
                    immer((darft) => {
                        const exam = darft.content.find((it) => it.id === id)
                        exam.hidden = res.hidden
                    })
                )
            })
        }

        if (!hidden) {
            Modal.confirm({
                title: '隐藏确认',
                content: '隐藏后，该模拟演练考试将不可被学生使用是否确认隐藏？',
                onOk: action,
            })
        } else {
            action()
        }
    }

    const onModalClose = () => {
        setFormStatus({ visible: false, exam: null })
    }
    const onModalOk = () => {
        fetch()
        setFormStatus({ visible: false, exam: null })
    }
    const onExamSortOk = () => {
        fetch().then(() => {
            setSortModal(false)
        })
    }
    return (
        <div className="maneuvers-list-page">
            <div className="page-header">
                <div className="page-title">模拟演练</div>
                <Link target="_blank" to="/mock">
                    <Button type="primary">模拟演练系统</Button>
                </Link>
            </div>
            <Title level={4} style={{ marginTop: '16px' }}>
                人力资源共享服务职业技能等级证书
            </Title>
            <p>选择试卷后，将会生成试卷快照，可进入模拟演练系统查看</p>
            <SearchForm onReset={() => fetch(1, 10, {})} onFinish={(value) => fetch(1, 10, value)} />
            <Space style={{ margin: '1em 0' }}>
                <Button onClick={() => setFormStatus({ visible: true, exam: null })} type="primary">
                    创建模拟演练
                </Button>
                <Button onClick={() => setSortModal(true)}>编辑排序</Button>
            </Space>
            <Table
                dataSource={exams.content}
                rowKey="id"
                pagination={{
                    total: exams.totalElements,
                    pageSize: exams.size,
                    current: exams.number + 1,
                    onChange: fetch,
                }}
                columns={[
                    {
                        title: '序号',
                        render: (value, record, index) => index + 1,
                    },
                    {
                        title: '名称',
                        dataIndex: 'name',
                    },
                    {
                        title: '等级',
                        render: (value) => {
                            return (
                                <div>
                                    <p>科目:{value.subjectName}</p>
                                    <p>等级:{value.levelName}</p>
                                </div>
                            )
                        },
                    },
                    {
                        title: '试卷名称',
                        dataIndex: 'paperName',
                    },
                    {
                        title: '分类',
                        dataIndex: 'category',
                    },
                    {
                        title: '显示状态',
                        dataIndex: 'hidden',
                        render: (hidden) => (hidden ? '隐藏' : '显示'),
                    },
                    {
                        title: '操作',
                        render: (value) => {
                            return (
                                <Space>
                                    <Button onClick={() => onDelItem(value.id)}>删除</Button>
                                    <Button onClick={() => onSettingModalClick(value)}>设置</Button>
                                    <Button onClick={() => onSwitch(value.id, value.hidden)}>
                                        {value.hidden ? '显示' : '隐藏'}
                                    </Button>
                                </Space>
                            )
                        },
                    },
                ]}
            />
            <ExamSetting visible={formStatus.visible} exam={formStatus.exam} onCancel={onModalClose} onOk={onModalOk} />
            <ExamSort visible={sortModal} onOk={onExamSortOk} onCancel={() => setSortModal(false)} />
        </div>
    )
}

/**
 * 模拟演练搜索表单
 */
const SearchForm = ({ onReset, onFinish }) => {
    return (
        <Form layout="vertical" className="mock-exam-search-form" onReset={onReset} onFinish={onFinish}>
            <div className="search-form-layout">
                <Form.Item label="科目" name="subject">
                    <Select
                        placeholder="全部"
                        options={[
                            { label: '全部', value: '' },
                            { label: '理论', value: 'Theory' },
                            { label: '实操', value: 'Practice' },
                        ]}
                        allowClear
                    />
                </Form.Item>
                <Form.Item label="等级" name="level">
                    <Select
                        placeholder="全部"
                        options={[
                            { label: '全部', value: '' },
                            { label: '初级', value: 'Beginner' },
                            { label: '中级', value: 'Intermediate' },
                            { label: '高级', value: 'Advanced' },
                        ]}
                        allowClear
                    />
                </Form.Item>
                <div />
                <Form.Item label="显示状态" name="hidden">
                    <Select
                        placeholder="全部"
                        options={[
                            { label: '全部', value: '' },
                            { label: '显示', value: false },
                            { label: '隐藏', value: true },
                        ]}
                        allowClear
                    />
                </Form.Item>
                <Form.Item label="名称" name="name">
                    <Input placeholder="输入考试名称" />
                </Form.Item>
                <Form.Item style={{ textAlign: 'right' }}>
                    <Space>
                        <Button htmlType="reset">重置</Button>
                        <Button type="primary" htmlType="submit">
                            搜索
                        </Button>
                    </Space>
                </Form.Item>
            </div>
        </Form>
    )
}

/**
 * 模拟演练排序
 */
const ExamSort = ({ visible, onOk, onCancel }) => {
    const [list, setList] = useState([])
    useEffect(() => {
        if (visible) {
            getAllMockExams().then((data) => setList(data))
        }
    }, [visible])

    const onDragEnd = (result) => {
        const { source, destination } = result
        if (destination && source.index !== destination.index) {
            setList(
                immer((draft) => {
                    const [sourceItem] = draft.splice(source.index, 1)
                    draft.splice(destination.index, 0, sourceItem)
                })
            )
        }
    }
    const _onOk = () => {
        const data = list.map((item) => item.id)
        updateMockExamSort(data).then(() => {
            onOk?.()
        })
    }

    return (
        <Modal visible={visible} title="排序" onOk={_onOk} onCancel={onCancel}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="exam-list">
                    {(provided, snapshot) => {
                        return (
                            <div
                                style={{
                                    padding: 8,
                                    backgroundColor: snapshot.isDraggingOver ? 'rgb(226 232 240)' : 'white',
                                }}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {list.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => {
                                            return (
                                                <div
                                                    className="exam-list-item"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div>
                                                        {index + 1}、{item.name}
                                                    </div>
                                                    <div
                                                        style={{ textAlign: 'center' }}
                                                    >{`${item.subjectName}-${item.levelName}`}</div>
                                                    <div>{item.paperName}</div>
                                                </div>
                                            )
                                        }}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )
                    }}
                </Droppable>
            </DragDropContext>
        </Modal>
    )
}

/**
 * 试卷选择及试卷名称显示
 */
const PaperName = ({ examId, value, onChange }) => {
    const [paperName, setPaperName] = useState('')
    const [visible, setVisible] = useState(false)
    const openModal = () => setVisible(true)

    useEffect(() => {
        if (!value) {
            setPaperName('')
            return
        }
        getPaperNames([value]).then((arr) => {
            setPaperName(arr?.[0]?.name)
        })
    }, [value])

    return (
        <div style={{ display: 'flex' }}>
            <Input
                readOnly
                placeholder="请选择"
                style={{ borderRight: 'none', cursor: 'pointer' }}
                onClick={() => {}}
                value={paperName}
                addonAfter={
                    value && (
                        <Link target="_blank" to={`/kw/paper/detail/${value}`}>
                            查看
                        </Link>
                    )
                }
            />
            <Button onClick={openModal}>选择试卷</Button>
            <PaperChooser
                visible={visible}
                setVisible={setVisible}
                examId={examId}
                handleChoose={(examId, paperId) => {
                    onChange(paperId)
                    return Promise.resolve()
                }}
            />
        </div>
    )
}

/**
 * 模拟演练设置
 */
const ExamSetting = ({ exam, visible = false, onCancel, onOk }) => {
    const [form] = Form.useForm()

    useEffect(() => {
        if (!exam) {
            form.resetFields()
        } else {
            form.setFieldsValue(exam)
        }
    }, [exam, form, visible])

    const _onOk = () => {
        form.validateFields().then((value) => {
            const res = exam ? updateMockExam(exam.id, value) : createMockExam(value)
            res.then(() => {
                onOk?.()
            })
        })
    }

    return (
        <Modal title="模拟演练" visible={visible} onCancel={onCancel} onOk={_onOk}>
            <Form form={form}>
                <Form.Item label="名称" rules={[{ required: true, whitespace: true }]} name={'name'}>
                    <Input minLength={1} maxLength={50} />
                </Form.Item>
                <Form.Item label="试卷" rules={[{ required: true }]} name={'paperOriginalId'}>
                    <PaperName examId={exam?.id} paperId={exam?.paperSnapshotId} />
                </Form.Item>
                <Form.Item label="科目" rules={[{ required: true }]} name={'subject'}>
                    <Select
                        options={[
                            { value: 'Theory', label: '理论' },
                            { value: 'Practice', label: '实操' },
                        ]}
                    />
                </Form.Item>
                <Form.Item label="等级" rules={[{ required: true }]} name={'level'}>
                    <Select
                        options={[
                            { value: 'Beginner', label: '初级' },
                            { value: 'Intermediate', label: '中级' },
                            { value: 'Advanced', label: '高级' },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    label="分类"
                    name={'category'}
                    help="模拟考试分类，用于区分不同比赛人群的练习(1x.topwaysoft.com/mock?category=分类)"
                >
                    <Input minLength={1} maxLength={50} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default Index
